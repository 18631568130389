$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.not_found {
	@include GlobalStyles-child-center('.not_found_image');
	position  : relative;
	display   : block;
	text-align: center;
	height    : 100%;
}

.not_found_image {
	position : relative;
	width    : 300px;
	max-width: 100%;
}

.has_expired {
	@include GlobalStyles-child-center('.has_expired_image');
	position  : relative;
	display   : block;
	text-align: center;
	height    : 100%;
}

.has_expired_image {
	position : relative;
	width    : 300px;
	max-width: 100%;
}