$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$LoginStyles-max_width: 450px;
$LoginStyles-body_top : $GlobalStyles-standard_space * 9;
$LoginStyles-bg_class:'body &:global(.GlobalStyles-slant_container_secondary .GlobalStyles-slant_container_background)';
$LoginStyles-header_height_mobile: 60px + ($GlobalStyles-standard_space*2);

.wrapper {
	@include GlobalStyles-child-center('.container');
	height: 100%;
}

.background_filler {
    @include ColorStyles-sdark;
    position  : fixed;
    top       : 50%;
    transform : translateY(78px);
    bottom    : 0px;
    width     : 100%;
}

.container {
	width     : 100%;
	text-align: center !important;
}

.header {
	.logo_container {
		position      : relative;
		vertical-align: middle;
		display       : inline-block;
		line-height   : normal;
	}
}

.body {
	@include GlobalStyles-slant_container_top($LoginStyles-body_top);

	.body_content_wrapper {
		@include GlobalStyles-floating_slight_card(true);
		position  : relative;
		text-align: left; 
		width     : 100%;
		max-width : $LoginStyles-max_width !important;
		display   : inline-block;
		padding   : $GlobalStyles-standard_space*4;
		z-index   : 2; 

		.input {
			width: 100%;
		}
	}
}

.register_link {
	float      : left;
    height     : 36px;
    line-height: 36px; 
    padding    : $GlobalStyles-standard_space;
    font-size  : $GlobalStyles-standard_font - 2px; 
}

.login_header_msg {
	position      : relative;
	padding-bottom: $GlobalStyles-standard_space;
	text-align    : center;
	font-size     : $GlobalStyles-standard_font;
}

@media all and (min-width: $GlobalStyles-screen-desktop) {

	.body {
		.body_content_boundary {
			padding-top   : $GlobalStyles-standard_space*4;
			padding-bottom: $GlobalStyles-standard_space*4;
		}
	}
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	.container {
		padding-top: $LoginStyles-header_height_mobile;
		height     : 100%;
	}

	// <withbackground>
	// .background_filler {
 //    	@include ColorStyles-s;
 //    	top: $GlobalStyles-standard_space*4;
	// }
	
	// <nobackground>
	.background_filler {
		display: none;
	}

	.header {
		position   : absolute !important;
		top        : 0px; 
		height     : $LoginStyles-header_height_mobile;
		line-height: $LoginStyles-header_height_mobile;
	}

	.logo_container {

		:global(.ImageStyles-image_size) {
			max-height: $LoginStyles-header_height_mobile - ($GlobalStyles-standard_space*2);
		}
	}

	.body {
		@include GlobalStyles-slant_container_top(0px,$LoginStyles-bg_class);
		@include GlobalStyles-slant_container_bottom(0px,$LoginStyles-bg_class);
		@include GlobalStyles-slant_container($LoginStyles-bg_class);
		height: 100%;

		// <withbackground>
		// .form {
		// 	@include GlobalStyles-form_secondary_theme;
		// 	@include GlobalStyles-link_secondary_theme;
		// 	color: $ColorStyles-stext;
		// }

		.body_content_boundary {
			@include GlobalStyles-child-center('.body_content_wrapper');
			height: 100%;

			.body_content_wrapper {
				background : transparent;
				box-shadow : none;
				border     : 0px;
				padding    : $GlobalStyles-standard_space*2 0px;
			}
		}
	}

	.register_link {
		text-align : center;
		height     : auto;
		line-height: normal;
		width      : 100%;

		a {
			display: block;
		}
	}
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) and (min-height:557px) {

	.body_content_wrapper {	
		// 56px is headers height
		margin-top: -76px;
	}
}

@media all and (max-width: $GlobalStyles-min_width + 5px) {

	.body_content_wrapper {	
		margin-top: 0px;
	}
}

@import '~theme/pages/login/scss/LoginStyles.module';