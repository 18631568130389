$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';
$GlobalStyles-stepper_number_size: 30px;

.stepper {
    width          : 100%; 
	padding-top    : $GlobalStyles-standard_space*2 !important;
	padding-bottom : $GlobalStyles-standard_space*2 !important;

	.stepper_link {
		position  : relative;
		text-align: center;
		display   : table-cell;
   		width     : 1%;
		padding   : $GlobalStyles-standard_space;
		min-width : 100px;

		&:first-child {

			.stepper_left_line {
				display: none;
			}
		}

		&:last-child {

			.stepper_right_line {
				display: none;
			}
		}

		&.stepper_enabled {

			.stepper_left_line {

				&::before {
					@include ColorStyles-slight;
				}

			}
		}

		&.stepper_next_enabled {

			.stepper_right_line {

				&::before {
					@include ColorStyles-slight;
				}

			}
		}


		&.stepper_active {
			background: rgba($ColorStyles-primary,.5) !important;

			.stepper_text {
   				color          : $ColorStyles-slight;
   				text-decoration: none !important;
			}
		}

		&.stepper_disabled {

	   		.stepper_readonly_text {

				.stepper_number {
					font-weight: bold;

					.stepper_number_circle {
						border: 2px solid $ColorStyles-primary;
						color : $ColorStyles-pdark;
					}
				}
				
				.stepper_text {
					color          : $ColorStyles-pdark;
   					text-decoration: none !important;
				}

	   		}
		}

		.stepper_line {
			position: absolute;
			width   : 50%;
			height  : 2px;
			top     : ($GlobalStyles-stepper_number_size/2) + $GlobalStyles-standard_space;
			z-index : 3;

			&::before {
				@include GlobalStyles-pseudo;
				@include ColorStyles-p;
				position: relative;
				height  : 100%;
				width   : 100%;
			}
		}

		.stepper_left_line {
			left         : 0px;
			padding-right: ($GlobalStyles-stepper_number_size/2) + $GlobalStyles-standard_space;
		}

		.stepper_right_line {
			right        : 0px;
			padding-left : ($GlobalStyles-stepper_number_size/2) + $GlobalStyles-standard_space;
		}

		.stepper_number {
			font-weight: bold;

			.stepper_number_circle,.stepper_done_circle {
				@include ColorStyles-plight;
				border       : 2px solid $ColorStyles-slight;
				display      : inline-block;
				height       : $GlobalStyles-stepper_number_size;
				line-height  : $GlobalStyles-stepper_number_size - 4px;
				width        : $GlobalStyles-stepper_number_size;
				border-radius: 50%;
				color        : $ColorStyles-secondary;
			}

			.stepper_done_circle {
				@include ColorStyles-slight;
			}
		}

		.stepper_text {
			@include GlobalStyles-link;
			padding-top: $GlobalStyles-standard_space;
		}
	}
}

.pages {
	position   : relative;
	white-space: pre-line;
	min-height : 100px; 
}

:global {

	.RealAccountStyles-logo {
		text-align: center;
		margin-top: $GlobalStyles-standard_space * 2;

		> div {
			width: 270px;
			
			img {
				width: 100%;
			}
		}
	}

	.RealAccountStyles-content_title {
		text-align    : center;
		padding-top   : $GlobalStyles-standard_space*2; 
		padding-bottom: $GlobalStyles-standard_space*4;
		margin-bottom : $GlobalStyles-standard_space;
		position      : relative; 
		color         : $ColorStyles-secondary;
		text-transform: uppercase;

		&::before {
			@include ColorStyles-pdark-fading-edges;
			@include GlobalStyles-pseudo;
			height: 1px;
			width : 100%;
			bottom: 0px;
		}
	}

	.RealAccountStyles-content_subtitle {
		font-size     : $GlobalStyles-standard_font;
		font-weight   : normal;
		text-transform: none;
	}

	.RealAccountStyles-number_code {
		width  : 200px;
		display: inline-block;
   		float  : left;

   		label {
   			> div {
   				white-space: nowrap;
   			}
   		}
	}

	.RealAccountStyles-number {
		display     : inline-block;
		width       : 100%;
		padding-left: 200px;
   		margin-left : -200px;
   		float       : left;

   		label {
   			display: block !important;
   		}
	}

	.RealAccountStyles-field_confirmation_checkbox {
		width  : 32px;
		display: inline-block;
   		float  : left;

   		label {
   			> div {
   				white-space: nowrap;
   			}
   		}
	}


	.RealAccountStyles-field_confirmation {
		display     : inline-block;
		width       : 100%;
		padding-left: 32px + ($GlobalStyles-standard_space/2) !important;
   		margin-left : -32px;
   		float       : left;

   		label {
   			display: block !important;
   		}
	}

	@media all and (max-width: 400px) {

		.RealAccountStyles-number_code {
			width: 120px;
		}


		.RealAccountStyles-number {
			padding-left: 120px;
	   		margin-left : -120px;
		}
	}

	.RealAccountStyles-form_title {
		@include ColorStyles-p(.6);
		color         : rgba($ColorStyles-ptext, .9);
		padding       : $GlobalStyles-standard_space/2 $GlobalStyles-standard_space;
		text-align    : center;
		text-transform: uppercase;
		margin-bottom : $GlobalStyles-standard_space * 2 !important;
	    margin-left   : -($GlobalStyles-standard_space * 4) !important;
	    margin-right  : -($GlobalStyles-standard_space * 4) !important;

		~ .RealAccountStyles-form_title {
			margin-top: $GlobalStyles-standard_space * 2 !important; 
		}
	}

	.RealAccountStyles-form_category_title {
		color         : rgba($ColorStyles-ptext, 1);
		margin        : $GlobalStyles-standard_space; 
		padding       : $GlobalStyles-standard_space/2 0px;
		border-bottom : 1px solid rgba($ColorStyles-primary, .6);
	}

	.RealAccountStyles-form_subtitle {
		font-size  : $GlobalStyles-standard_font + .5px;
		font-weight: normal;
	}

	.RealAccountStyles-modal_text {
		padding: 0px $GlobalStyles-standard_space;
	}

	.RealAccountStyles-row_center {
		text-align: center;
	}

	.RealAccountStyles-row_right {
		text-align: right;
	}

	.RealAccountStyles-row_spaced {
		margin-top: $GlobalStyles-standard_space*2 !important;
	}

	.RealAccountStyles-input_labled_year {

   		.FormStyles-input_container {
			padding-right: 30px + ($GlobalStyles-standard_space * 2);
   		}
	}

	.RealAccountStyles-post_label_year {
		width       : 30px + ($GlobalStyles-standard_space * 2);
		text-align  : center;
		display     : inline-block;
   		margin-right: -(30px + ($GlobalStyles-standard_space * 2));
   		font-weight : bold;
   		opacity     : .5;  
	}

	.RealAccountStyles-error_message {
		position: relative !important;
	}
	
	.RealAccountStyles-block {
		@include GlobalStyles-block;
		margin: 0px $GlobalStyles-standard_space;

		&.RealAccountStyles-block_top {
			@include GlobalStyles-block_top;
		}

		&.RealAccountStyles-block_bottom {
			@include GlobalStyles-block_bottom;
		}

	    &.RealAccountStyles-block_start {
			@include GlobalStyles-block_start;
	    }

		.RealAccountStyles-label,.RealAccountStyles-content {
			@include GlobalStyles-block_div;
		}

		.RealAccountStyles-content {
			@include GlobalStyles-block_content;
		}

		.RealAccountStyles-label {
			@include GlobalStyles-block_label;
		}


		.RealAccountStyles-content_colon {
			@include GlobalStyles-block_content_colon;
		} 

		.RealAccountStyles-content_text {
			@include GlobalStyles-block_content_text;
		}

		ul,ol {
			@include GlobalStyles-block_ul;
		}
	}

	.RealAccountStyles-select_yes {
		text-align: justify;

		.RealAccountStyles-select_yes_text {
			font-weight: bold;
		}

		.RealAccountStyles-select_yes_mandatory {
			color: $ColorStyles-perror;
		}

    	input {
    		max-width: 240px !important;
		}
		
		p {
			margin-top   : 3px;
			margin-bottom: 20px;
			text-align   : center;
			font-weight  : bold;
		}
	}

	.RealAccountStyles-trading_company_display {
		@include ColorStyles-p(.5);
		border-radius : $GlobalStyles-radius;
		padding       : $GlobalStyles-standard_space;
		display       : inline-block;
    	vertical-align: top;
	}

	.RealAccountStyles-radio_with_input {


		.RealAccountStyles-radio_input {
			padding: $GlobalStyles-standard_space 0px;

			.RealAccountStyles-radio_text {
				display       : inline-block;
				margin-right  : $GlobalStyles-standard_space;
				vertical-align: middle;
			}

			label {
				display: none;
			}

			input {
				width : auto;

			}
		}

	}

	.RealAccountStyles-com_1_spa {

		table {
			min-width: 100%;

			tr th {
				&:nth-child(1) {
					@include GlobalStyles-td_width(35px);
				}

				&:nth-child(3) {
					@include GlobalStyles-td_width(110px);
				}
			}
		}
	}


	.RealAccountStyles-com_2_spa {

		table {
			min-width: 100%;

			tr th {
				&:nth-child(1) {
					@include GlobalStyles-td_width(35px);
				}

				&:nth-child(3),&:nth-child(4) {
					@include GlobalStyles-td_width(110px);
				}

			}
		}
	}

	.RealAccountStyles-table {
		position : relative;
		padding  : $GlobalStyles-standard_space*2  $GlobalStyles-standard_space*3;
	    margin   : 0 auto;
	    max-width: 1058px;

	    &.RealAccountStyles-broker {

	    	table {

				tr th {
					&:nth-child(1) {
						@include GlobalStyles-td_width(100px);
					}
					&:nth-child(2) {
						@include GlobalStyles-td_width(250px);
					}
					&:nth-child(3) {
						@include GlobalStyles-td_width(100px);
					}
				}

				.RealAccountStyles-broker_td_style td {
					padding-top	  : 0px;
					padding-bottom: 0px;
					border-top    : 0px;
					border-bottom : 0px;				
				}
				
				.RealAccountStyles-broker_tr_style td:first-child {
					padding-top   : 4px;
					padding-bottom: 4px;
					border-bottom : 1px solid rgba($ColorStyles-pdark,.5);
				}
				
				.RealAccountStyles-broker_td_border_bottom_true td {
					vertical-align: middle;
					border-bottom : 1px solid rgba($ColorStyles-pdark,.5);
				}
			}
	    }

	    &.RealAccountStyles-bank {
	    	table {

				tr th {
					&:nth-child(1) {
						@include GlobalStyles-td_width(60%);
					}

					&:nth-child(3) {
						@include GlobalStyles-td_width(40%);
					}
				}

				.RealAccountStyles-currency {
					display  : inline-block;
					min-width: 40px;
				}
			}
	    }


		table {
			min-width: 100%;

			td {
				vertical-align: top;
			}
		}
	}

	.RealAccountStyles-trading_experience_modal_form {
		padding-top   : $GlobalStyles-standard_space * 3;
		padding-bottom: 0.8rem;
		padding-left  : 0.8rem;
		padding-right : 0.8rem;
	}
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	.stepper {
		height : 100px;
		padding: $GlobalStyles-standard_space 0px !important;

		.stepper_link {

			.stepper_number {

				.stepper_number_circle,.stepper_done_circle {
					height       : 22px;
					line-height  : 22px - 4px;
					width        : 22px;
				}
			}
		}
	}

	// <nobackground>
	.stepper {
        border-top: 1px solid darken($ColorStyles-plight, 5%);
		background: darken($ColorStyles-plight,4%);
	}

	:global {

		.RealAccountStyles-select_yes {

	    	input {
	    		max-width: none !important;
	    	}
		}

		.RealAccountStyles-content_subtitle {
			font-size: $GlobalStyles-standard_font - 3px;
		}

		.RealAccountStyles-trading_experience_modal_form {
			padding-top   : $GlobalStyles-standard_space * 2;
			padding-bottom: 0.4rem;
			padding-left  : 0.4rem;
			padding-right : 0.4rem;
		}
	}
}

@media all and (max-width: $GlobalStyles-screen-tablet) {
	:global {
		.RealAccountStyles-com_1_spa, .RealAccountStyles-com_2_spa {
			table {
				table-layout: auto !important;
				width       : auto !important;
				min-width   : 100% !important;
				white-space : nowrap;
			}
		}
	}
}

@media all and (max-width: $GlobalStyles-screen-mobile - 1px) {
	:global {
		.RealAccountStyles-form_title {
			margin-left : -($GlobalStyles-standard_space * 2) !important;
			margin-right: -($GlobalStyles-standard_space * 2) !important;
		}

		.RealAccountStyles-trading_experience_modal_form {
			padding-bottom: 0.1rem;
			padding-left  : 0.1rem;
			padding-right : 0.1rem;
		}
	}
}

@media all and (max-width: ($GlobalStyles-screen_mobile - 150) - 1px) {
	:global {
		.RealAccountStyles-table {
			&.RealAccountStyles-bank {
				table {
					tr th {
						&:nth-child(1) {
							@include GlobalStyles-td_width(128px);
						}
						
						&:nth-child(2) {
							@include GlobalStyles-td_width(177px);
						}
					}
				}
			}
		}
	}
}