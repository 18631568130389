$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.overlay {
	padding   : $GlobalStyles-standard_space;
	background: rgba(darken($ColorStyles-sdark,20%),.8);
}

.header {
	padding-top   : 0.8rem;
	padding-bottom: $GlobalStyles-standard_space;
	font-weight   : bold;
	font-size     : $GlobalStyles-standard_font + 4px;
	color         : $ColorStyles-secondary;
	text-align    : center;
}

.footer {
	text-align: right;
}

.parts {

	+.parts {
		margin-top: $GlobalStyles-standard_space;
	}

	:global(.AppStyles-notif_id) ~ :global(.AppStyles-notif_en) {
		margin-top: $GlobalStyles-standard_space;
	}
}

.small .modal {
	width    : 800px;
	max-width: 380px;
}

.large .modal {
	width    : 800px;
	max-width: 800px;
}

@import '~theme/components/modal/scss/ModalStyles.module';