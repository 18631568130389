$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$HomeStyles-max_width   : 360px;
$HomeStyles-row_size    : 90px;
$HomeStyles-row_padding : $GlobalStyles-standard_space*2;
$HomeStyles-body_top    : $GlobalStyles-standard_space * 5;

.wrapper {
	@include GlobalStyles-child-center('.v_center_container');
	height : 100%;
	padding: $GlobalStyles-standard_space*2 0px;

	&.loading {

		:global(.GlobalStyles-ghost_ui) {
			display: block;
		}
	}
}

.background_filler {
    @include ColorStyles-sdark;
    position  : fixed;
    top       : 50%;
    transform : translateY(78px);
    bottom    : 0px;
    width     : 100%;
}

.v_center_container {
	@include GlobalStyles-slant_container_top($HomeStyles-body_top);
	width: 100%;
}

.content_boundary {
	width     : 100%;
	text-align: center;
}

.body_content_wrapper {
	position  : relative;
	text-align: left; 
	width     : 100%;
	max-width : $HomeStyles-max_width !important;
	display   : inline-block;
	z-index   : 2; 
}

.body_content {
	position: relative;
}

.account_type {
	padding       : $HomeStyles-row_padding;
	font-size     : $GlobalStyles-standard_font + 2px;
	font-weight   : bold;
	text-align    : center;  
	color         : $ColorStyles-sdark;
	text-transform: uppercase;
}

.row {
	@include GlobalStyles-clearfix;
	height      : $HomeStyles-row_size;
	line-height : $HomeStyles-row_size - ($HomeStyles-row_padding*2);
	padding     : $HomeStyles-row_padding;
	position    : relative;

	&.realaccount_button {
		line-height: normal;
		height     : auto;
	}

	&.username {

		.row_text {
			font-size  : $GlobalStyles-standard_font + 2px;
			font-weight: bold;
		}
		
	}

	&.etradedemo {

		.etradedemo_title {
			font-size  : $GlobalStyles-standard_font + 2px;
			font-weight: bold;
			display    : block; 
		}
		
	}

	:global(.Routes-realaccount) {
		@include GlobalStyles-button;
		width    : 100%;
	}
}

.row_icon {
	@include ColorStyles-slight(.7);
	width        : $HomeStyles-row_size - ($GlobalStyles-standard_space*4);
	height       : $HomeStyles-row_size - ($GlobalStyles-standard_space*4);
	line-height  : $HomeStyles-row_size - ($GlobalStyles-standard_space*4) !important;
	text-align   : center; 
	font-size    : $HomeStyles-row_size - ($GlobalStyles-standard_space*6);
	border-radius: 50%;
	float        : left;
	transform    : translate(0px, 0px) !important;
}

.row_text {
	display    : inline-block;
	float      : left;
	margin-left: $HomeStyles-row_padding;
	position   : relative;
}

.row_text_multiline {
	line-height   : normal;
	display       : inline-block;
	vertical-align: middle;
}

.etradedemo_title_container, .tradepro_fx_container,.button_container {
	position: relative;
}

.step_display {
	@include ColorStyles-slight(.2); 
	font-weight: bold;
	color      : $ColorStyles-ptext;
	text-align : center;
	padding    : $GlobalStyles-standard_space; 
}

@media all and (min-width: $GlobalStyles-screen-desktop) {

	.body_content_wrapper {
		@include GlobalStyles-floating_slight_card(true);
	}

	.row {
		border-top: 1px solid darken($ColorStyles-plight, 10%);
	}
	
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
	// <withbackground>
	// .account_type {
	// 	color: lighten($ColorStyles-secondary,60%);
	// }

	// .background_filler {
 //    	@include ColorStyles-s;
 //    	top: $GlobalStyles-standard_space*3;
	// }

	// .v_center_container {
	// 	@include GlobalStyles-slant_container_top(0px);
	// 	height: 100%;
	// }

	// .content_boundary {
	// 	@include GlobalStyles-child-center('.body_content_wrapper');
	// 	height : 100%;
	// }

	// .body_content_wrapper {
	// 	padding: $GlobalStyles-standard_space*3 0px;
	// }

	// .row {
	// 	@include ColorStyles-slight(.2);
	// 	@include GlobalStyles-link_secondary_theme;

	// 	+ .row {
	// 		margin-top: $GlobalStyles-standard_space;
	// 	}

	// 	:global(.Routes-realaccount) {
 //            @include GlobalStyles-form_secondary_theme_primary_button;
	// 	}
	// }

    // <nobackground>
    .wrapper {
		padding: 0px;
	}

	.background_filler {
    	@include ColorStyles-plight;
    	top: $GlobalStyles-standard_space*3;
	}

	.v_center_container {
		@include GlobalStyles-slant_container_top(0px);
		height: 100%;
	}

	.content_boundary {
		@include GlobalStyles-child-center('.body_content_wrapper');
		height : 100%;
	}

	.body_content_wrapper {
		padding   : $GlobalStyles-standard_space*2 0px;
	}

	.row {
		border    : 1px solid darken($ColorStyles-plight, 10%);

		+ .row {
			margin-top: -1px;
		}
	}

}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) and (min-height: 390px) {

	.body_content_wrapper {	
		// 56px is headers height
		margin-top: -56px;
	}
}