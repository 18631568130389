$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$Loader-loader_size   : 35px;

.wrapper {
	@include GlobalStyles-center(absolute);
	@include GlobalStyles-clearfix();
	font-size     : $GlobalStyles-standard_font - 2px;
	font-weight   : normal;
	text-transform: none;
	line-height   : 0px; 
	width         : $Loader-loader_size;
	height        : $Loader-loader_size;
    border-radius : 50%;
    overflow      : hidden;
    max-height    : 100%;
    max-width     : 100%;
}
    
.spinners {
 	position: relative;
	width 	: 100%;
	height  : 100%;

	.spinners_child {
		width   : 100%;
		height  : 100%;
		position: absolute;
		left    : 0;
		top     : 0;

		&::before {
			@include ColorStyles-slight;
			@include KeyframeStyles-sk_circleBounceDelay(1.2s infinite ease-in-out both);
			content         : '';
			display         : block;
			margin          : 0 auto;
			width           : 15%;
			height          : 15%;
			border-radius   : 100%;
			border          : 1px solid $ColorStyles-primary;
		}

		$Loader-spinner_degree: 30deg;
		$Loader-spinner_delay : -1.1s;
    	@for $ctr from 2 through 12 {

			&.spinners_child:nth-child(#{$ctr}) {
	    		transform: rotate($Loader-spinner_degree);

				&:before {
					animation-delay: $Loader-spinner_delay;
				}
			}

			$Loader-spinner_degree: $Loader-spinner_degree + 30deg;
			$Loader-spinner_delay : $Loader-spinner_delay  + .1s;
    	}

	}
}