$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

:global(body.PrivateLayoutStyles-menu_open) {
	overflow: hidden;
}

:export { mobile_width:  $GlobalStyles-screen-desktop - 1px };