$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.ghost_block {
	height  : $GlobalStyles-standard_font;
	position: relative;
	display : inline-block;
	margin  : $GlobalStyles-standard_space/4;
}

.ghost {
	width   : 100%;
	position: relative;

	:global(.GlobalStyles-ghost_ui) {
		display: block;
	}
}