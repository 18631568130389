$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.content {
	position: relative;
	
	.image_container {

		&.loading {
			:global(.GlobalStyles-ghost_ui) {
				display: block;
			}
		}
		
	}

	.image, .image_container {
		position  : relative;
		width     : 100%;
	    background: darken($ColorStyles-plight,1%);
	}

	.wording {
		margin    : 0px;
		margin-top: $GlobalStyles-standard_space; 
	}
} 

.wysiwyg {
	padding: $GlobalStyles-standard_space;
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {

	.content {

		.wording {
			margin-top: 0px;
			padding   : $GlobalStyles-standard_space*2;
		}

		.image {
			border-bottom : 1px solid darken($ColorStyles-plight,10%);
		}
	}

	.error {
		position: relative;
	}

	.wysiwyg {
		padding: $GlobalStyles-standard_space * 2;
	}
}