$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';


.wrapper {
	padding: $GlobalStyles-standard_space;
}

.content_wrapper {
	@include GlobalStyles-card;
	max-width: 500px;
	margin   : 0 auto;
}

.logo {
	padding   : $GlobalStyles-standard_space * 2; 
	text-align: center;

	img {
		width: 100%;
	}
}

.title {
	@include ColorStyles-p(.6);
	font-weight: bold; 
}

.title {
	border-bottom: 1px solid $ColorStyles-primary;
	padding      : $GlobalStyles-standard_space $GlobalStyles-standard_space*2;
    word-break   : break-all; 
}

.block {
	@include GlobalStyles-block;

	.label,.content {
		@include GlobalStyles-block_div;
	}

	.content {
		@include GlobalStyles-clearfix;
	}

	.label {
		@include GlobalStyles-block_label;
	}


	.content_colon {
		@include GlobalStyles-block_content_colon;
	} 

	.content_text {
		@include GlobalStyles-block_content_text;
	}
}