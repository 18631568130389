$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$ToastStyles-icon_size: 30px;

.wrapper {
	@include GlobalStyles-clearfix;
	padding: $GlobalStyles-standard_space;

	&.has_icon {
		padding-left: $ToastStyles-icon_size + ($GlobalStyles-standard_space*3);
	}
}

.icon {
	@include ColorStyles-slight(.1);
	color           : inherit;
	float           : left;
	height          : $ToastStyles-icon_size;
	width           : $ToastStyles-icon_size;
	line-height     : $ToastStyles-icon_size;
	text-align      : center; 
	margin-left     : -($ToastStyles-icon_size  + ($GlobalStyles-standard_space*2));
	font-size       : $GlobalStyles-standard_font + 4px; 
	border-radius   : 50%; 
	opacity         : .9;
}

.default {
	border-radius: $GlobalStyles-radius !important;
    color        : $ColorStyles-ptext    !important;
}

.success {
    background: $ColorStyles-ssuccess !important;
    color     : $ColorStyles-stext    !important;
}

.error {
    background: $ColorStyles-serror   !important;
    color     : $ColorStyles-stext    !important;
}

.title {
	font-weight: bold;
}

.close_button {
	@include ColorStyles-slight(.1);
	color        : inherit;
	position     : absolute;
	right        : $GlobalStyles-standard_space;
	top          : $GlobalStyles-standard_space - 2px;
	border-radius: 50%; 
	opacity      : .7;
	transition   : $GlobalStyles-transition;
	padding      : $GlobalStyles-standard_space/8; 

	&:hover {
		opacity: 1;
	}
}