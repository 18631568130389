$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
$FontIconStyles-loaded: ();

@mixin FontIconStyles-default_style() {
    transform     : translate(0, 2px);
    font-weight   : normal;
    font-style    : normal;
    display       : inline-block;
    text-transform: none;
    letter-spacing: normal;
    word-wrap     : normal;
    white-space   : nowrap;
    direction     : ltr;
    line-height   : normal;
    
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;

    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

//**
//----------------------------------------------------------------------------------------------------------------------
//  MATERIAL DESIGN
//----------------------------------------------------------------------------------------------------------------------
// Emplementation Guide: http://google.github.io/material-design-icons/
// NOTE : intead of writing directly the font content, we made md- classes 
// to let css do the inserting of content through psuedo element ::before
//
@mixin FontIconStyles-load_material() {

    @if (index($FontIconStyles-loaded, material) == null) {

        $FontIconStyles-loaded: append($FontIconStyles-loaded, material) !global;

        @at-root (without: all) {
            @font-face {
                font-family: 'Material Icons';
                font-style : normal;
                font-weight: 400;
                src        : local('Material Icons'),
                            local('MaterialIcons-Regular'),
                            url(../fonts/materialdesign/MaterialDesignIconicFont.woff2) format('woff2'), 
                            url(../fonts/materialdesign/MaterialDesignIconicFont.ttf)   format('truetype');
            }
        }

    }

}

@mixin FontIconStyles-material_design() {
    @include FontIconStyles-load_material();
    @include FontIconStyles-default_style;
    font-family: 'Material Icons';
}

@mixin FontIconStyles-material_lock() {
    &::before {
        content: 'lock';
    }
}

.material {
    @include FontIconStyles-material_design;
}

.material_error::before {
    content: 'error';
}

.material_error_outline::before {
    content: 'error_outline';
}

.material_clear::before {
    content: 'clear';
}

.material_lock::before {
    content: 'lock';
}

.material_close::before {
    content: 'close';
}

.material_home::before {
    content: 'home';
}

.material_person_add::before {
    content: 'person_add';
}

.material_exit_to_app::before {
    content: 'exit_to_app';
}

.material_person::before {
    content: 'person';
}

.material_menu::before {
    content: 'menu';
}

.material_chevron_right::before {
    content: 'chevron_right';
}

.material_chevron_left::before {
    content: 'chevron_left';
}

.material_expand_more::before {
    content: 'expand_more';
}

.material_done::before {
    content: 'done';
}

.material_arrow_forward::before {
    content: 'arrow_forward';
}

.material_arrow_back::before {
    content: 'arrow_back';
}

.material_timeline::before {
    content: 'timeline';
}

.material_show_chart::before {
    content: 'show_chart';
}

.material_cloud_upload::before {
    content: 'cloud_upload';
}

.material_note::before {
    content: 'note';
}

.material_info::before {
    content: 'info';
}

.material_cloud_off::before {
    content: 'cloud_off';
}

.material_rotate_right::before {
    content: 'rotate_right';
}

.material_edit::before {
    content: 'edit';
}

.material_visibility::before {
    content: 'visibility';
}

.material_visibility_off::before {
    content: 'visibility_off';
}