$COMPANY: "bpf";
            $NODE_ENV: "production";
            $secondary: #005aa0;;
            $slight   : #5086d2;;
            $sdark    : #003271;;
            $stext    : #ffffff;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.table_container {
    overflow-y: auto; 
    // 1px is for outlines to be visible
    padding   : $GlobalStyles-standard_space 1px;
    

    @media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
        font-size: $GlobalStyles-standard_font - 2px; 
    }

	.table {
        border-color: transparent;

		&.table_striped {
		    tr:nth-child(even) {
		        @include ColorStyles-pdark(.2);
		    }
		}

		&.table_bordered {
		    td, th {
		        border-width: 1px;
		    }
        }
	}
	
    td, th {
        padding     : $GlobalStyles-standard_space/2;
        border-width: 0px;
        border-style: solid;
    }

    th {
        @include ColorStyles-s;
        border-color: rgba($ColorStyles-sdark,.3);
    }

    td {
        border-color : rgba($ColorStyles-pdark,.5);
        outline-color: $ColorStyles-slight !important;
    }
}

@import '~theme/components/table/scss/TableStyles.module';